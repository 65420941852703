<template>
  <b-card
    :class="cardClasses"
    footer-class="px-2"
  >
    <h5 class="d-flex">
      <span class="me-1">
        <font-awesome-icon icon="building" class="fa-fw" />
      </span>
      <span>{{companyName}}</span>
    </h5>
    <div class="ms-4 ps-2">
      <p>
        <strong>{{taxCodeLabel}}</strong>
        {{taxCode}}
      </p>
      <p class="mt-n2 mb-0">
        <strong>{{addressLabel}}</strong>
        {{address}}
      </p>
    </div>

    <div
      v-if="hasExtraIcons"
      class="card--profile-search__icons"
    >
      <div
        v-if="hasClickReport"
        v-b-tooltip.hover
        :title="hasClickReportTooltip"
      >
        <inline-svg
          :src="require('../../../assets/img/icons/CLICKREPORT.svg')"
          fill="#0aa2c0"
          color="#0aa2c0"
          height="15px"
          width="15px"
        ></inline-svg>
      </div>
    </div>

    <template #footer>
      <div class="d-md-flex justify-content-md-end">
<!--        <div-->
<!--          v-if="hasClickReport"-->
<!--          class="card&#45;&#45;profile-search__icons"-->
<!--        >-->
<!--          <div-->
<!--            v-b-tooltip.hover-->
<!--            :title="hasClickReportTooltip"-->
<!--          >-->
<!--            <inline-svg-->
<!--              :src="require('../../../assets/img/icons/CLICKREPORT.svg')"-->
<!--              fill="#0aa2c0"-->
<!--              color="#0aa2c0"-->
<!--              height="15px"-->
<!--              width="15px"-->
<!--            ></inline-svg>-->
<!--          </div>-->
<!--        </div>-->
        <b-button
          variant="info"
          size="sm"
          class="d-block d-md-inline-block"
          @click="routeTo()"
        >
          {{itemButtonLabel}}
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'ProfileSearchDataSearchItem',
  components: { InlineSvg },
  props: {
    data: Object,
    context: String,
  },
  computed: {
    companyName() {
      // console.log('? item?', this.data);
      if (isNotEmpty(this.data?.companyNameUsed)) return this.data.companyNameUsed;
      return isNotEmpty(this.data?.info?.companyName) ? this.data.info.companyName : '';
    },
    naMessage() {
      return 'N.D.';
    },
    taxCodeLabel() {
      return 'P.IVA: ';
    },
    taxCode() {
      if (isNotEmpty(this.data?.taxCodeUsed)) return this.data.taxCodeUsed;
      if (isNotEmpty(this.data?.vatNumberUsed)) return this.data.vatNumberUsed;
      return isNotEmpty(this.data?.info?.taxCode) ? this.data.info.taxCode : '';
    },
    addressLabel() {
      return 'Indirizzo:';
    },
    address() {
      let path;
      let streetName;
      let streetNumber;
      let zip;
      let city;
      let province;
      if (isNotEmpty(this.data?.legalAddress)) {
        path = this.data.legalAddress;
      } else if (isNotEmpty(this.data?.mainAddressUsed)) {
        path = this.data.mainAddressUsed;
      }
      if (isNotEmpty(path)) {
        streetName = path.streetName ? `${path.streetName}, ` : '';
        streetNumber = path.streetNumber ? `${path.streetNumber} - ` : '';
        zip = path.zip ? `${path.zip} ` : '';
        city = path.city ? `${path.city} ` : '';
        province = path.province ? `(${path.province})` : '';
        return streetName + streetNumber + zip + city + province;
      }
      return this.naMessage;
    },
    itemButtonLabel() {
      return 'Visualizza';
    },
    currentContext() {
      return this.$props.context;
    },
    hasClickReport() {
      return this.data?.clickReportAvailable;
    },
    hasExtraIcons() {
      return this.hasClickReport;
    },
    hasClickReportTooltip() {
      return 'Click report disponibile';
    },
    cardClasses() {
      const base = 'bg-gray-100 card--profile-search';
      if (this.hasExtraIcons) return `${base} card--profile-search--has-icons`;
      return base;
    },
  },
  methods: {
    routeTo() {
      const baseUrl = this.currentContext === 'click' ? 'mf-solvency' : 'company-search';
      this.$router.push(`/${baseUrl}/company/${this.taxCode}`);
    },
  },
};
</script>

<style scoped>

</style>
